import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, GTR } from 'styles/tokens'

const StyledList = styled.ol`
  list-style: none;
  counter-reset: my-awesome-counter;
`

const StyledListItem = styled.li`
  counter-increment: my-awesome-counter;
  display: flex;
  margin-right: ${GTR.L};

  &:before {
    content: counter(my-awesome-counter);
    font-weight: bold;
    margin-right: ${GTR.L};
    color: ${COLOR.ACCENT.PRIMARY};
  }

  &:not(:last-of-type) {
    margin-bottom: ${GTR.M};
  }
`

const NumberedList = ({ items }) => {
  return (
    <StyledList>
      {items.map((item) => (
        <StyledListItem key={item}>{item}</StyledListItem>
      ))}
    </StyledList>
  )
}

NumberedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default NumberedList
