import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import { H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

import NumberedList from 'components/NumberedList'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "pritesh-and-team-with-whiteboard.jpg" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <StyledWrapper>
      <Wrapper container>
        <H size="XL" centered margin={[0, 0, 'XL']}>
          Register with us
        </H>
        <Grid>
          <Grid.Item spanFromM={5}>
            <H size="L" color={COLOR.ACCENT.PRIMARY}>
              How it works
            </H>
            <NumberedList
              items={[
                'Register your details below',
                'Once you’ve sent us your CV and details, our team will go through the information provided. If we recruit for the type of roles you’re looking for, one of our team will be in touch as soon as possible, to discuss your job search with you.',
                'We’ll also keep you posted with helpful resources and exciting projects that we’re getting involved in.',
                'When we have a role of interest, we will get in touch to share more information, and we’ll be by your side throughout the whole hiring process.',
                'Our contact doesn’t end after placement, we’ll check in with you to see how things are going in your new role.',
              ]}
            />
          </Grid.Item>
          <Grid.Item spanFromM={6} startColumnFromM={7}>
            <Image fluid={image.childImageSharp.fluid} />
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Hero
