import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { Link } from 'gatsby'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import omit from 'lodash/omit'
import map from 'lodash/map'

import { boolToStr } from 'lib/text'
import scrollToDataId from 'lib/window/scroll-to-data-id'
import phoneNumberValidation from 'lib/validation/phone-number'

import CATEGORIES from 'constants/jobs/categories'
import PATHS from 'routes/paths'
import { BORDER, COLOR, GTR } from 'styles/tokens'

import Box from 'components/Box'
import SuccessHeading from 'components/SuccessHeading'
import { Grid, Wrapper } from 'components/common'
import Button from 'components/common/Button'
import Input from 'components/form/Input'
import Checkbox from 'components/form/Checkbox'
import Error from 'components/form/Error'
import Label from 'components/form/Label'
import FileUpload from 'components/FileUpload'
import Switch from 'components/form/Switch'

const REGISTER_ZAP_URL = 'https://hooks.zapier.com/hooks/catch/7432126/oiglz89/'

const categoryOptions = CATEGORIES.map(({ title }) => ({
  value: title,
  label: title,
}))

const StyledBox = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.L};
  border-radius: ${BORDER.RADIUS.S};
  margin-bottom: ${GTR.L};
`

const ErrorSummary = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  color: ${COLOR.STATE.ERROR};
  margin-bottom: ${GTR.L};
`

const InnerForm = ({ error }) => {
  const { errors, touched, values, isSubmitting } = useFormikContext()
  const visibleErrors = pick(errors, Object.keys(touched))
  const showErrorSummary = !isEmpty(visibleErrors)

  const showDesiredSalary = values.employmentType.includes('Permanent')
  const showDesiredDayRate = values.employmentType.includes('Contract')

  return (
    <Form>
      <Grid>
        <Grid.Item spanFromL={8} startColumnFromL={3}>
          <StyledBox>
            <Grid columns={2}>
              <Grid.Item span={2}>
                <Input name="firstName" label="First name" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="lastName" label="Last name" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="email" type="email" label="Email" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="phoneNumber" label="Phone number" type="tel" />
              </Grid.Item>
            </Grid>
          </StyledBox>
          <StyledBox>
            <Grid columns={2}>
              <Grid.Item span={2}>
                <FileUpload name="cv" label="CV" maxSize={1572864} />
              </Grid.Item>
              <Grid.Item span={2}>
                <FileUpload
                  name="portfolio"
                  label="Portfolio (required for design roles)"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="portfolioUrl" label="or Portfolio link" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="linkedInUrl" label="LinkedIn profile" optional />
              </Grid.Item>
            </Grid>
          </StyledBox>
          <StyledBox>
            <Grid columns={2}>
              <Grid.Item span={2}>
                <Switch
                  name="eligableToWorkInUk"
                  label="Are you currently eligible to work in the UK?"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Switch
                  name="willingToRelocate"
                  label="Are you willing to relocate?"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input
                  name="desiredLocation"
                  label="What’s your desired location?"
                />
              </Grid.Item>
            </Grid>
          </StyledBox>
          <StyledBox>
            <Grid columns={2}>
              <Grid.Item span={2}>
                <Input
                  name="currentNoticePeriod"
                  label="How long is your current notice period?"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Label>What type of role are you looking for?</Label>
                <Error name="employmentType" />
              </Grid.Item>

              <Grid.Item span={2} spanFromM={1}>
                <Grid columns={1}>
                  <Grid.Item span={1}>
                    <Checkbox
                      name="employmentType"
                      value="Permanent"
                      label="Permanent"
                      showError={false}
                    />
                  </Grid.Item>
                  {showDesiredSalary && (
                    <Grid.Item span={1}>
                      <Input
                        name="desiredSalary"
                        label="What’s your desired salary?"
                      />
                    </Grid.Item>
                  )}
                </Grid>
              </Grid.Item>

              <Grid.Item span={2} spanFromM={1}>
                <Grid columns={1}>
                  <Grid.Item span={1}>
                    <Checkbox
                      name="employmentType"
                      value="Contract"
                      label="Contract"
                      showError={false}
                    />
                  </Grid.Item>
                  {showDesiredDayRate && (
                    <Grid.Item span={1}>
                      <Input
                        name="desiredDayRate"
                        label="What’s your desired day rate?"
                      />
                    </Grid.Item>
                  )}
                </Grid>
              </Grid.Item>

              <Grid.Item span={2} spanFromM={1}>
                <Input
                  component="select"
                  name="jobCategory"
                  label="What type of job are you looking for?"
                  options={categoryOptions}
                  placeholder="Select job category"
                />
              </Grid.Item>
            </Grid>
          </StyledBox>
          <Grid>
            <Grid.Item spanFromM={6} startColumnFromM={4}>
              <Checkbox
                name="terms"
                label={
                  <>
                    I agree to the <Link to={PATHS.TERMS}>Terms of use</Link>{' '}
                    and <Link to={PATHS.PRIVACY}>Privacy Policy</Link>
                  </>
                }
              />
              <Wrapper centered margin={['XL', 0, 0]}>
                {error && <ErrorSummary>{error}</ErrorSummary>}
                {showErrorSummary && (
                  <ErrorSummary>
                    Please complete all required fields.
                  </ErrorSummary>
                )}
                <Button.Primary type="submit" stretch loading={isSubmitting}>
                  Submit application
                </Button.Primary>
              </Wrapper>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

InnerForm.propTypes = {
  error: PropTypes.string,
}

InnerForm.defaultProps = {
  error: '',
}

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('This field is required.'),
  phoneNumber: yup
    .string()
    .test(...phoneNumberValidation)
    .required('This field is required.'),
  cv: yup.mixed().required('This field is required.'),
  desiredLocation: yup.string().required('This field is required.'),
  currentNoticePeriod: yup.string().required('This field is required.'),
  employmentType: yup
    .array()
    .min(1, 'Please select at least one type of role.'),
  desiredSalary: yup.string().when('employmentType', {
    is: (val) => val.includes('Permanent'),
    then: yup.string().required('This field is required.'),
  }),
  desiredDayRate: yup.string().when('employmentType', {
    is: (val) => val.includes('Contract'),
    then: yup.string().required('This field is required.'),
  }),
  jobCategory: yup.string().required('This field is required.'),
  terms: yup.boolean().oneOf([true], 'This field is required.'),
})

const Register = () => {
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    setError(null)

    const formData = new FormData()

    const jobCategoryLabel = categoryOptions.find(
      ({ value }) => value === values.jobCategory
    )?.label

    formData.append('eligableToWorkInUk', boolToStr(values.eligableToWorkInUk))
    formData.append('willingToRelocate', boolToStr(values.willingToRelocate))
    formData.append('employmentType', values.employmentType.join('/'))
    formData.append('desiredSalary', values.desiredSalary || 'N/A')
    formData.append('desiredDayRate', values.desiredDayRate || 'N/A')
    formData.append('jobCategory', jobCategoryLabel)

    map(
      omit(values, [
        'eligableToWorkInUk',
        'willingToRelocate',
        'employmentType',
        'desiredSalary',
        'desiredDayRate',
        'jobCategory',
      ]),
      (value, key) => {
        formData.append(key, value)
      }
    )

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    try {
      await axios.post(REGISTER_ZAP_URL, formData, config)
      setIsSuccessful(true)
      scrollToDataId('form')
    } catch (e) {
      // eslint-disable-next-line
      Sentry.captureException(e)
      setError(
        'There was an a problem submitting the form. Please try again, or send us an email at hello@pixel-pond.com.'
      )
    }
  }

  return (
    <Wrapper background={COLOR.BACKGROUND.PALE} data-scroll-id="form">
      <Wrapper container>
        {!isSuccessful && (
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              cv: null,
              portfolio: null,
              portfolioUrl: '',
              linkedInUrl: '',
              eligableToWorkInUk: true,
              willingToRelocate: true,
              currentNoticePeriod: '',
              desiredLocation: '',
              employmentType: ['Permanent'],
              desiredSalary: '',
              desiredDayRate: '',
              jobCategory: '',
              terms: false,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <InnerForm error={error} />
          </Formik>
        )}
        {isSuccessful && (
          <Box>
            <Wrapper padding={['M', 0]} centered>
              <SuccessHeading size="L">Thanks for registering</SuccessHeading>
              <p>You should receive a confirmation email.</p>
            </Wrapper>
          </Box>
        )}
      </Wrapper>
    </Wrapper>
  )
}

export default Register
