import React from 'react'
import PropTypes from 'prop-types'
import shuffle from 'lodash/shuffle'

import { COLOR } from 'styles/tokens'
import Layout from 'components/layout'
import SEO from 'components/seo'
import FeaturedJobs from 'components/FeaturedJobs'
import ApplicationForm from './Form'

import Hero from './Hero'

const Register = ({ pageContext: { featuredJobs } }) => (
  <Layout>
    <SEO
      title="Register with us"
      description="We're into long-term relationships, register with Pixel Pond to start your journey."
    />
    <Hero />
    <ApplicationForm />
    <FeaturedJobs
      jobs={shuffle(featuredJobs).slice(0, 3)}
      background={COLOR.BACKGROUND.PALE}
    />
  </Layout>
)

Register.propTypes = {
  pageContext: PropTypes.shape({
    featuredJobs: PropTypes.array.isRequired,
  }).isRequired,
}

export default Register
