import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, GTR } from 'styles/tokens'
import { Field, useFormikContext } from 'formik'
import { screenMax } from 'styles/helpers/responsive'

import { Wrapper } from 'components/common'
import Label from 'components/form/Label'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`

const StyledCircle = styled.span`
  content: '';
  position: absolute;
  top: 3px;
  right: 3px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  transition: 0.2s;
  background: ${COLOR.WHITE};
  border: 2px solid ${COLOR.BLACK};

  ${screenMax.s`
    width: 29px;
    height: 29px;
    border-radius: 29px;
  `}
`

const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
  width: 70px;
  height: 40px;
  background-color: ${COLOR.GREY.LIGHT};
  border-radius: 40px;
  transition: all 0.2s;
  opacity: 1;
  margin: 0 ${GTR.M};

  ${screenMax.s`
    width: 60px;
    height: 35px;
    border-radius: 35px;
  `}
`

const StyledCheckbox = styled(Field)`
  display: none;

  &:checked + ${StyledCircle} {
    right: calc(100% - 3px);
    transform: translateX(100%);
  }
`

const Switch = ({ name, label, color }) => {
  const { values } = useFormikContext()
  const isOn = values[name]
  return (
    <>
      <Label htmlFor={`input-${name}`}>{label}</Label>

      <StyledWrapper>
        <span>Yes</span>
        <StyledLabel style={{ backgroundColor: isOn && color }}>
          <StyledCheckbox
            type="checkbox"
            checked={isOn}
            name={name}
            id={`input-${name}`}
          />
          <StyledCircle />
        </StyledLabel>
        <span>No</span>
      </StyledWrapper>
    </>
  )
}

Switch.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

Switch.defaultProps = {
  color: COLOR.ACCENT.PRIMARY,
}

export default Switch
