export default [
  {
    title: 'Design & User Experience',
    shortTitle: 'Design & UX',
    seoTitle: 'UX & Design Jobs',
    seoDescription:
      'Are you a UI Designer or Head of Design looking for your next move? We’ve got UX Designer jobs, User Research roles & product opportunities.',
    url: '/design-and-user-experience',
    image: 'man-doing-design-on-computer.png',
    teamImage: 'nick-with-stripy-shirt.jpg',
    roles: [
      'User Experience Designer',
      'User Interface Designer',
      'User Researcher',
      'Product Designer',
      'Head of User Experience',
      'Interaction Designer',
    ],
    about: [
      'In the Design and User experience discipline you’ll use empathy, technical aptitude, visual awareness and a range of specialist software tools to create accessible, aesthetically appealing and meaningful applications and sites.',
      'User experience (UX) involves the design of digital products and services, such as websites and mobile apps, for specific target groups and end users. The purpose of your role is to ensure that the user experience for individuals is as efficient and pleasurable as possible - in order to achieve this, understanding research principles is vital.',
      'Our designers work collaboratively with other members of the digital team and the wider business to ensure that products and services meet client needs.',
    ],
  },
  {
    title: 'Product',
    seoTitle: 'Product Jobs',
    seoDescription:
      'If you’re looking for program or product manager jobs, your next move as a product owner or head of product. You’re in the right place.',
    url: '/product',
    image: 'man-in-cafe-with-laptop.jpg',
    teamImage: 'team/cleo-smiling-towards-camera.jpg',
    roles: [
      'Product Manager',
      'Product Owner',
      'Head of Product',
      'Scrum Master',
      'Product Strategist',
      'Program Manager',
    ],
    about: [
      'Building digital products requires technical experts who understand how technology can make life easier. Project engineers need to understanding user problems, and then create solutions which take advantage of the latest technological advancements, and that are simple to use.',
      'They’re responsible for the success of a product, defining features and constantly looking to improve ways of working or creating new products - most importantly bringing value.',
      'Their job can span from planning requirements with internal customers, and usher projects through the entire project lifecycle. Managing project schedules, identifying risks and clearly communicating goals to stakeholders. Projects can often span offices and time zones, so keeping all the cogs coordinated is key.',
      'Career progression can lean towards Head-of-product positions as well as focussing on a speciality to become a domain expert.',
    ],
  },
  {
    title: 'Engineering',
    seoTitle: 'Software & Development Jobs',
    seoDescription:
      'Looking for your next software engineer challenge? Find your next developer job with Pixel Pond.',
    url: '/engineering',
    image: 'three-people-in-booth-with-laptop.jpg',
    teamImage: 'frederico-and-claire.jpg',
    roles: [
      'Software Engineer',
      'Full stack developer',
      'Front-end developer',
      'Chief Technical Officer',
      'Mobile Developer',
      'Engineering Manager',
    ],
    about: [
      'A firm understanding of new technology and infrastructures is crucial for our software engineers, as well as a love of problem solving.',
      'Whether it’s front-end (frameworks such as React or Angular) or back-end (databases and behind the scenes structures) all developers must know how to be team-players, as they are constantly collaborating with the multidisciplinary team.',
      'There’s no typical route, and many self-taught developers do this job, although having a college diploma or computer science degree won’t hurt. The most important factor that sets our candidates apart is mastery of a variety of languages and frameworks, showing versatility.  Always looking to develop your knowledge and self-train is an important trait to have as the industry moves at such a rapid pace.',
    ],
  },
  {
    title: 'Data & Analytics',
    seoTitle: 'Data Jobs',
    seoDescription:
      'Into Analytics and Data? Why not take a look at our data science and data analyst jobs?',
    url: '/data-and-analytics',
    image: 'man-with-laptop-on-yellow-chair.jpg',
    teamImage: 'team/ajay-with-laptop.jpg',
    roles: [
      'Data Scientist',
      'Data Engineer',
      'Head of Data Engineering',
      'Data Architect',
      'Data Analyst',
      'Technical Analyst',
    ],
    about: [
      'Finding meaning in data is a unique skill. We look for candidates who can translate numbers into a human language, and use them to help businesses reach their goals.',
      'The data landscape has transformed in the past decade, and we’re now generating over 50 times the amount we did in 2011. The “Data & Analytics” discipline can be considered an interdisciplinary solution to the explosion of data that takes old data analytics approaches, and uses machines to augment and scale their effects on larger data sets.',
      'Roles within this sector, involve large datasets and have an analytical approach - you’ll often need to understand and use programming, math, and technical communication skills. Most importantly, you need to have a sense of curiosity to understand the world through numbers and patterns.',
    ],
  },
  {
    title: 'Brand & Creative',
    seoTitle: 'Brand & Creative Jobs',
    seoDescription:
      'Are you a creative at heart? Find your next role as an artworker, illustrator or graphic designer with Pixel Pond',
    url: '/brand-and-creative',
    image: 'woman-smiling-with-headphones.jpg',
    teamImage: 'frederico-and-claire.jpg',
    roles: [
      'Artworker',
      'Creative',
      'Motion Designer',
      'Art Director',
      'Creative Director',
      'Graphic Designer',
    ],
    about: [
      'Roles for creatives can vary depending on the placement. Some clients will look to hire a pair, whereas others look for just one person with a specific speciality – usually either design or copywriting.',
      'Creatives can come from a number of backgrounds, and although formal advertising training can be useful, it is by no means expected. Original ideas, and dynamic thinkers are what really gets clients excited.',
      'We find creative talent for a whole spectrum of clients - from in-house product companies to global brand agencies. Our people understand how to communicate a vision and combine creativity with commercial understanding.',
    ],
  },
  {
    title: 'Content',
    seoTitle: 'Content Jobs',
    seoDescription:
      'Got a thing for words? We’ve got plenty of jobs for budding copywriters, content strategists and social media marketers.',
    url: '/content',
    image: 'girl-on-sofa-with-dog.jpg',
    teamImage: 'team/clare-with-white-shirt.jpg',
    roles: [
      'Content designer',
      'Copywriter',
      'UX Writer',
      'Content Strategist',
      'Social Media Manager',
      'Head of Content',
    ],
    about: [
      'Content roles require a creative mind, and candidates must demonstrate solid presentation and writing skills. This discipline also requires a lot of flexibility, organisation skills and an understanding of the intended audience to take the company’s strategy in the right direction.',
      'Candidates need to have a great understanding of the platforms available, and be able to demonstrate how to use these effectively and creatively.',
      'Clients for these roles vary, and can be either marketing agencies, or direct clients looking for help communicating online. There isn’t a traditional route into a job in content, but usually candidates are able to display their expertise by sharing past projects.',
    ],
  },
  {
    title: 'Finance & Legal',
    seoTitle: 'Legal & Finance Jobs',
    seoDescription:
      'Good with numbers? Check out our legal and finance jobs for your next challenge.',
    url: '/finance-and-legal',
    image: 'girl-with-laptop-in-bar.jpg',
    teamImage: 'team/cleo-smiling-towards-camera-hand.jpg',
    roles: [
      'Finance Manager',
      'Chief Financial Officer',
      'Bookkeeper',
      'Financial Controller',
      'Head of Legal',
      'Legal Counsel',
    ],
    about: [
      'Finance and legal teams within tech companies need more than advanced numerical and analytical skills, they also need creative minds. These professionals must be prepared to reinvent internal methods and think outside the box.',
      'These are people that are a proactive force within the company, they are always adding value and have an understanding of operational issues. Candidates with chartered status or those that are qualified solicitors are better equipped to progress to roles such as Chief Finance Officer (CFO) or Chief Legal officer (CLO).',
      'Specialisms include Accountancy, Tax, Intellectual property, Commercial, Regulation etc. Outside of the company their work could be involved with auditing firms, or external legal firms, particularly during periods of raising finance.',
    ],
  },
  {
    title: 'People',
    seoTitle: 'People & HR Jobs',
    seoDescription:
      'Good with people? Find your next operations job as a HR manager, Head of People or COO.',
    url: '/people',
    image: 'girl-with-yellow-jacket.jpg',
    teamImage: 'team/cleo-smiling-towards-camera.jpg',
    roles: [
      'Talent Acquisition Manager',
      'Head of People',
      'Human Resources Manager',
      'Operations Manager',
      'Development Manager',
      'Chief Operations Officer',
    ],
    about: [
      'We know all about the value of finding the right people for your team - we make tech teams better (not just bigger) every day. People, Human resources, Operations, Administrative jobs; these are all essential to the functioning of our clients - be it a growing start-up or a global tech giant.',
      'Candidates with HR management specialization, professional diplomas or qualifications in the fields of law, sociology and/or psychology, are best equipped to gain access to senior/head of positions.',
      'This discipline is uniquely placed to drive positive change in the world of work. By understanding and unlocking the value of an organisation’s people, it enables sustainable business outcomes. People professionals support the vision and goals of the organisation by really understanding people - taking a human approach. While the nature of specific roles may vary, they empower an organisation’s people to thrive.',
    ],
  },
]
